import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import MenuIcon from "../components/icons/menuIcon";

const Header = ({ siteTitle }) => {
  return (
    <header className="navTitle w-screen text-eggshell fixed flex justify-between py-8 px-6 text-xl uppercase z-20 font-montserrat items-center">
      <h1 className="m-0">
        <Link to="/">
          <span className="chinese">润泰</span> {siteTitle}
        </Link>
      </h1>

      <div className="flex items-center text-xs">
        {/* <div className="mr-4">
          <a className="text-white mr-1" href>
            EN
          </a>
          <span className="text-eggshell">|</span>
          <a className="ml-1 text-eggshell">CN</a>
        </div> */}
        <div className="flex flex-column items-center">
          <MenuIcon color="text-eggshell" />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
