import React from "react";
import PropTypes from "prop-types";

const MenuIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={color && color}
  >
    <line x1="9" y1="15" x2="21" y2="15"></line>
    <line x1="3" y1="9" x2="21" y2="9"></line>
  </svg>
);

export default MenuIcon;

MenuIcon.propTypes = {
  color: PropTypes.string,
};

MenuIcon.defaultProps = {
  color: ``,
};
